<template>
    <div class="task">
        <div class="task_flex" @click="show_main = !show_main">
            <div>
                <user_login :size="'40px'" :style="{'height': '40px'}"/>
            </div>
            <div>
                <div class="title">Daily Steaks</div>
                <div class="level">Day {{current}}/10</div>
            </div>
            <div class="arrow" :class="[show_main ? 'rot' : '']">
                <arrow_vue :size="'20px'"/>
            </div>
        </div>
        <div class="tasks_main" v-if="show_main">
            <div class="days" v-if="show_days">
                <day_vue v-for="(day, i) in days" :key="i" :day="day" :current="current" :to_claim="to_claim" :pos="i"
                @claimed="init()"/>
            
            </div>
        </div>
    </div>
</template>

<script setup>
import moment from 'moment';
import arrow_vue from '@/icons/arrow.vue';
import user_login from '../../icons/user_login.vue';
import day_vue from './login/day.vue'

import { ref } from 'vue';
import { store } from '@/store/store';

const show_main = ref(false)

const show_days = ref(true)

const days = ref([
    {
        'no': 1,
        'title': 'Day 1',
        'reward': 50
    },
    {
        'no': 2,
        'title': 'Day 2',
        'reward': 100
    },
    {
        'no': 3,
        'title': 'Day 3',
        'reward': 200
    },
    {
        'no': 4,
        'title': 'Day 4',
        'reward': 300
    },
    {
        'no': 5,
        'title': 'Day 5',
        'reward': 500
    },
    {
        'no': 6,
        'title': 'Day 6',
        'reward': 1000
    },
    {
        'no': 7,
        'title': 'Day 7',
        'reward': 1500
    },
    {
        'no': 8,
        'title': 'Day 8',
        'reward': 2000
    },
    {
        'no': 9,
        'title': 'Day 9',
        'reward': 3000
    },
    {
        'no': 10,
        'title': 'Day 10',
        'reward': 5000
    }
])

const to_claim = ref(0)
const current = ref(0)

const init = () => {
    if (store.today == store.daily_login_last) {
        to_claim.value = 1000
        current.value = store.daily_login_count

        return
    }

    if (store.daily_login_count == 0) {
        to_claim.value = 0
        current.value = 0
    }
    if (store.daily_login_count > 0) {
        
        let last_claimed = moment(store.daily_login_last)
        let today = moment(store.today)

        let diff = today.diff(last_claimed, 'days')

        console.log('dddd', diff);

        if (diff == 1) {
            to_claim.value = store.daily_login_count
            current.value = store.daily_login_count
        } else {
            to_claim.value = 0
            current.value = 0
        }
    }

    if (store.daily_login_count >= 10) {
        to_claim.value = 0
        current.value = 0
    }
}
init()

</script>

<style scoped>
.task {
    margin-top: 20px;
    
    border: 1px solid #4b4f54;
    border-radius: 10px;
    overflow: hidden;

    fill: white;
}
.task_flex {

    display: flex;
    align-items: center;
    gap: 15px;
    padding: 10px;
}
.title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 3px;
}
.level {
    font-size: 12px;
    font-weight: 500;
    color: #dadada;
}
.arrow {
    margin-left: auto;
    transition: .2s;
}
.rot {
    transform: rotate(90deg);
}

.tasks_main {
    border-top: 1px solid #4b4f54;
    padding: 10px;

}
.days {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 10px;
}

.soon {
    font-size: 14px;
    font-weight: 500;
    color: #dadada;
}
</style>