<template>
    <div class="home">
        <div class="upper">

            <div class="welcome">
                <div class="name">
                    Welcome <span> {{ user }}</span>
                </div>
                <div class="wallet" @click="soon()">
                    <wallet_vue :size="'20px'"/>
                </div>
            </div>
            
            <div class="balance">
                <pizza_icon :size="'35px'"/>
                <div class="number">{{store.pizza.toLocaleString()}}</div>
            </div>

            <level_vue :level_details="level_details"/>

            <div class="pizza">
                <div class="pizza_icon" @click="tap($event)">
                    <pizza_icon :size="'100%'"/>
                </div>
            </div>

            <div class="ingredients">
                <div class="title">Ingredients
                    <router-link to="/ingredients">
                        <div class="icon">
                            <boost_icon :size="'20px'"/>
                        </div>
                    </router-link>
                </div>
                <div class="left_right">
                    <div v-for="ingredient in store.ingredients" :key="ingredient.name" v-show="ingredient.show">
                        <div v-if="ingredient.show">
                            <ingredient_vue :ingredient="ingredient"/>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="lower">
            <navigation_vue/>
        </div>

        <div>
            <plus_one v-for="(data, i) in plus_data" :data="data" :key="data.date" 
                @close="remove_plus_one(i)"/>
        </div>
    </div>
</template>

<script setup>
import wallet_vue from '@/icons/unfilled/wallet.vue';
import level_vue from '@/components/level.vue'
import pizza_icon from "@/icons/pizza.vue";

import boost_icon from '@/icons/boost.vue';

import ingredient_vue from '@/components/home/ingredient.vue';

import navigation_vue from '@/components/navigation.vue'
import plus_one from '@/components/plus_one.vue'

import { ref, watch } from 'vue';

import { useWebApp } from 'vue-tg'

const initdata = useWebApp()
const user = initdata.initDataUnsafe.user ? initdata.initDataUnsafe.user.first_name : 'None User'

import { store } from '@/store/store'


import { toast } from "vue3-toastify";
const soon = () => {
    toast.info('Wallet connect will be avaliable soon')
}

const plus_data = ref([])

const remove_plus_one = () => {
    plus_data.value.shift()
}

const tap = (e) => {
    // if (store.guage_balance >= store.recharge_speed) {
    //     store.balance += store.recharge_speed
    //     store.guage_balance -= store.recharge_speed
    // }
    

    plus_data.value.push({
        'x': e.clientX,
        'y': e.clientY,
        'date': (new Date()).getTime()
    })
    

    let not_enough = false

    for (let i of level_details.value.ingredients_index) {
        if (store.ingredients[i]['balance'] < store.ingredients[i]['amt_per_pizza']) not_enough = true
    }
    if (not_enough) return
    
    for (let i of level_details.value.ingredients_index) {
        store.ingredients[i]['balance'] -= store.ingredients[i]['amt_per_pizza']

    }

    store.total_pizza += 1
    store.pizza += 1

    store.updates++
    store.new_update = true
}


const level_details = ref({
    title: '',
    min: 0,
    max: 0,
    ingredients: [],
    ingredients_index: []
})

const level = ref(0)
const init = () => {

    for (let j = 0; j < store.ingredients.length; j++) {
        store.ingredients[j].show = false
    }

    let count = 0
    for (let x of store.levels) {
        if (store.total_pizza >= x.min ) {
            level.value = count
        }
        count++
    }
    level_details.value = store.levels[level.value]


    for (let i of [...level_details.value.ingredients]) {
        let index = 0
        for (let y of store.ingredients) {

            if (i == y.name) {
                store.ingredients[index].show = true
                level_details.value.ingredients_index.push(index)
            }
            index++
        }
    }
}
init()


watch(() => store.total_pizza, () => {
    let old_level_name = level_details.value.title

    let level = 0
    let count = 0
    for (let x of store.levels) {
        if (store.total_pizza >= x.min ) {
            level = count
        }
        count++
    }
    let level_name = store.levels[level].title

    if (old_level_name != level_name) {
        init()
    }
})

</script>


<style scoped>
.home {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.upper {
    flex: auto;
    padding: 20px;

    overflow: auto;
}
.welcome {
    /* background-color: #282b30; */
    /* padding: 15px; */
    /* border-radius: 6px; */

    display: flex;
    justify-content: space-between;
    align-items: center;
}
.name {
    font-size: 14px;
    color: gray;
}
.name span {
    font-weight: 600;
    color: white;
}
.wallet {
    fill: white;
}



.balance {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    fill: #FFD700;
    gap: 10px;
    margin: 30px 0px;
}
.number {
    font-size: 40px;
    font-weight: 600;
    line-height: 38px;
}
.symbol {
    font-size: 10px;
    color: gray;
}


.pizza {
    
    display: flex;
    align-items: center;
    justify-content: center;
    
    margin-top: 30px;
}
.pizza_icon {
    fill: #FFD700;
    width: 60%;
    max-width: 300px;
    border-radius: 50%;
    border: 5px solid #fff;
    
    display: flex;
    align-items: center;
    justify-content: center;

    transition: .2s;
}
.pizza_icon:active {
    border-color: #B5CB00;
    transform: scale(1.05) rotate(10deg);
    
}

.ingredients {
    margin-top: 30px;
}
.title {
    font-weight: 600;
    margin-bottom: 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    fill: white;
}
.left_right {
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    gap: 20px;
}
</style>