<template>
    <div class="referral">
        <!-- <div>
            <user_vue :size="'30px'"/>
        </div> -->
        <div>
            <div class="name">
                {{ props.user.firstname }}
            </div>
            <div class="pizza">
                <pizza_vue :size="'13px'"/> {{ props.user.pizza }}
            </div>
        </div>
        <div class="flex_left">
            <div class="claiming" v-if="loading">Claiming...</div>
            <div v-else>
                <div class="cliamed" v-if="claimed">Bonus Claimed</div>
                <div class="claim" v-else @click="claim()">Claim Bonus</div>
            </div>
        </div>
    </div>
</template>

<script setup>
// import user_vue from '@/icons/user.vue';
import pizza_vue from '@/icons/pizza.vue';
import { store } from '@/store/store';
import { defineProps, inject, ref } from 'vue';

const props = defineProps(['user'])

const loading = ref(false)
const axios = inject('axios')

const claimed = ref(false)
let user = {...props.user}
claimed.value = user.referred_by_bonus_claimed


import { toast } from "vue3-toastify";

const claim = () => {

    loading.value = true
    
    store.pause_emiting = true

    axios.post('/user/referral/claim',{
        'user_id': store.user_id,
        'telegram_id': props.user.telegram_id
    }).then(data => {
        let r = data.data
        if (r.status == 'ok') {
            toast.success('Bonus Claimed')

            store.total_pizza += r.referral_bonus
            store.pizza += r.referral_bonus

            claimed.value = true

            loading.value = false
            
            store.pause_emiting = false
        } 
    })
}

</script>

<style scoped>
.referral {
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 5px;

    padding: 10px 5px;
    border-bottom: 1px solid #4b4f54;
    margin-bottom: 10px;
}
.name {
    font-weight: 600;
}
.pizza {
    margin-top: 5px;
    font-size: 12px;

    display: flex;
    align-items: center;
    gap: 5px;
    fill: #FFD700;
}
.flex_left {
    margin-left: auto;
    font-size: 12px;
    font-weight: 500;
}
.claim {
    padding: 5px;
    color: #FFD700;
}
</style>