<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M264.62-120q-26.85 0-45.74-18.88Q200-157.77 200-184.62v-350.76q0-26.85 18.88-45.74Q237.77-600 
            264.62-600H320v-80q0-66.85 46.58-113.42Q413.15-840 480-840t113.42 46.58Q640-746.85 640-680v80h55.38q26.85 0 
            45.74 18.88Q760-562.23 760-535.38v350.76q0 26.85-18.88 45.74Q722.23-120 695.38-120H264.62Zm0-40h430.76q10.77 0 
            17.7-6.92 6.92-6.93 6.92-17.7v-350.76q0-10.77-6.92-17.7-6.93-6.92-17.7-6.92H264.62q-10.77 0-17.7 6.92-6.92 6.93-6.92 
            17.7v350.76q0 10.77 6.92 17.7 6.93 6.92 17.7 6.92ZM480-300q25.31 0 42.65-17.35Q540-334.69 
            540-360t-17.35-42.65Q505.31-420 480-420t-42.65 17.35Q420-385.31 420-360t17.35 42.65Q454.69-300 
            480-300ZM360-600h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z"/>
        </svg>
        
    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>