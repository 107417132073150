<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="m704.62-165.23-53.39-53.39q-4.46-4.46-10.54-4.84-6.07-.39-11.31 4.84-5.23 5.24-5.23 10.93 0 5.69 5.23 
            10.92l68 68q5.24 5.23 10.8 7.35 5.56 2.11 11.92 2.11t11.82-2.11q5.46-2.12 10.7-7.35l68-68q4.46-4.46 
            4.84-10.54.39-6.07-4.84-11.31-5.24-5.23-10.93-5.23-5.69 0-11.19 5.5l-53.12 
            53.12v-133.23q0-6.16-4.61-10.77-4.62-4.62-10.77-4.62-6.15 0-10.77 4.62-4.61 4.61-4.61 10.77v133.23ZM479.85-466.77q8.84 
            0 16.99-2.37 8.14-2.37 15.47-6.32l227.61-131.62q7.15-3.94 9.19-12.09 
            2.04-8.14-1.98-15.26-4.03-7.12-12.08-9.15-8.05-2.04-15.13 2.12L480-502.92 240.08-641.46q-7.08-4.16-15.13-2.12-8.05 
            2.03-12.08 9.15-4.02 7.12-1.78 15.26 2.24 8.15 9.22 12.09l227.38 131.62q7.24 3.95 15.28 6.32t16.88 
            2.37ZM160-335.62v-288.76q0-17.39 8.48-32.15 8.48-14.76 23.83-23.78l255.38-147.15q8.46-5 16-6.85 7.54-1.84 
            16.27-1.84t16.81 1.84q8.08 1.85 15.54 6.85l255.38 147.15q15.35 9.02 23.83 23.78 8.48 14.76 8.48 32.15v134.46q0 
            14.15-11.15 22.61-11.16 8.46-25.85 5.77-10.08-2.69-20.95-3.27-10.88-.57-22.05-.57-105.8 0-180.59 74.79T464.62-210v15.99q0 
            7.42 1.23 13.66 2.46 19.5-12.58 30.81-15.04 11.31-31.19 
            2.62L192.31-279.69q-15.35-9.02-23.83-23.78-8.48-14.76-8.48-32.15ZM720-50q-66.85 0-113.42-46.58Q560-143.15 
            560-210t46.58-113.42Q653.15-370 720-370t113.42 46.58Q880-276.85 880-210T833.42-96.58Q786.85-50 720-50Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>