<template>
    <div class="ingredient">
        <div class="grid">
            <div>
                <div class="name">{{props.ingredient.name}}</div>
                <div class="numbers"> <number_format :no="props.ingredient.balance"/>/<number_format :no="props.ingredient.max"/> {{props.ingredient.mesurement}}</div>
                <div class="per">
                    Tier {{ props.ingredient.tier }}
                </div>
            </div>
            <div>
                <RadialProgress 
                :diameter="40"
                :completed-steps="props.ingredient.tier" :startColor="'#FFD700'"
                :total-steps="10" :strokeWidth="3" :innerStrokeWidth="3">
                    <div v-if="props.ingredient.can_lock && props.ingredient.lock" class="lock">
                        <lock_vue :size="'20px'"/>
                    </div>
                    <div class="percent" v-else>{{ props.ingredient.tier}}/10</div>
                </RadialProgress>
            </div>
        </div>
        <div class="upgrade" @click="show_pop = true">
            Upgrade <number_format :no="props.ingredient.tiers[props.ingredient.tier + 1]"/>
            <pizza_icon :size="'15px'" :style="{'height': '15px'}"/>
        </div>

        <popup v-if="show_pop" :ingredient="props.ingredient" @close="show_pop = false"/>
    </div>
</template>

<script setup>
import RadialProgress from "vue3-radial-progress";

import lock_vue from "@/icons/unfilled/lock.vue";

import number_format from '@/components/number_format.vue'
import pizza_icon from "@/icons/pizza.vue";

import popup from './popup.vue'

import { defineProps, ref } from "vue";

const props = defineProps(['ingredient'])
const show_pop = ref(false)
</script>

<style scoped>
.ingredient {
    border: 1px solid #4b4f54;
    /* background-color: #282b30; */
    border-radius: 10px;
    overflow: hidden;
}
.grid {
    padding: 10px;
    display: grid;
    gap: 5px;
    grid-template-columns: auto 40px;
}
.name {
    font-size: 14px;
    font-weight: 600;
}
.numbers {
    margin-top: 5px;
    font-size: 10px;
    color: #dadada;
}
.per {
    margin-top: 5px;
    font-size: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
    fill: #FFD700;
    color: #dadada;
}
.lock {
    fill: white;
}
.percent {
    font-size: 10px;
    font-weight: 500;
}


.upgrade {
    padding: 7px;
    font-size: 12px;
    font-weight: 600;
    color: #dadada;
    text-align: center;
    fill: #FFD700;
    border-top: 1px solid #4b4f54;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    
    transition: .3s;
}
.upgrade:active {
    background-color: #FFD700;
    color: white;
    fill: white;
}
</style>