<template>
    <div class="referrals">
        <div class="flex" @click="open_main()">
            <div>
                <ref_vue :size="'40px'" :style="{'height': '40px'}"/>
            </div>
            <div>
                <div class="title">Referrals</div>
                <div class="level">{{store.referrals}}</div>
            </div>
            <div class="arrow" :class="[show_main ? 'rot' : '']">
                <arrow_vue :size="'20px'"/>
            </div>
        </div>
        <div class="main" v-if="show_main">

            <referral_vue v-for="(user, i) in referrals" :key="i" :user="user"/>

            <div class="loading" v-if="loading">
                <div class="pizza">
                    <pizza :size="'20px'"/>
                </div>
            </div>

            <div class="none" v-if="referrals.length == 0 && !loading">
                <ref_vue :size="'25px'"/>
                Your referrals will appear here
            </div>
        </div>
    </div>
</template>

<script setup>
import ref_vue from '@/icons/unfilled/ref.vue'
import arrow_vue from '@/icons/arrow.vue';
import pizza from '@/icons/pizza.vue';

import referral_vue from './referral.vue';

import { store } from '@/store/store';
import { inject, ref } from 'vue';

const referrals = ref([])
const show_main = ref(false)
const loading = ref(false)

const axios = inject('axios')

const open_main = () => {
    show_main.value = !show_main.value

    if (loading.value) return

    if (referrals.value.length == 0) {
        loading.value = true

        axios.post('/user/referrals', {
            user_id: store.user_id
        }).then(data => {
            let r = data.data
            if (r.status == 'ok') {
                referrals.value = r.referrals
                loading.value = false

                store.referrals = r.referrals.length
            }
        })
    }
}

</script>

<style scoped>

.referrals {
    margin-top: 20px;
    
    border: 1px solid #4b4f54;
    border-radius: 10px;
    overflow: hidden;

    fill: white;
}
.flex {

    display: grid;
    grid-template-columns: 40px auto max-content;
    align-items: center;
    gap: 15px;
    padding: 15px 10px;
}
.title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 3px;
}
.level {
    font-size: 12px;
    font-weight: 500;
    color: #dadada;
}
.arrow {
    margin-left: auto;
    transition: .2s;
}
.rot {
    transform: rotate(90deg);
}

.main {
    border-top: 1px solid #4b4f54;
    padding: 15px;
}

.loading {
    padding: 20px 0px;
    text-align: center;
}
.pizza {
    animation: 2s spin infinite linear;
    margin: 0 auto;
    width: 20px;
    height: 20px;
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


.none {
    padding: 30px 0px;
    text-align: center;
    fill: gray;
    color: gray;
    font-size: 12px;
}

</style>