<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960" >
            <path d="M324.67-298.46q13.1 0 21.91-8.86 8.8-8.86 8.8-21.96 0-13.1-8.86-21.91T324.56-360q-13.1 0-21.91 8.86-8.8 8.86-8.8 21.96 0 13.1 8.86 21.91t21.96 8.81Zm0-150.77q13.1 0 21.91-8.86 8.8-8.86 8.8-21.96 0-13.1-8.86-21.91t-21.96-8.81q-13.1 0-21.91 8.86-8.8 8.86-8.8 21.96 0 13.1 8.86 21.91t21.96 8.81Zm0-150.77q13.1 0 21.91-8.86 8.8-8.86 8.8-21.96 0-13.1-8.86-21.91t-21.96-8.81q-13.1 0-21.91 8.86-8.8 8.86-8.8 21.96 0 13.1 8.86 21.91t21.96 8.81Zm143.02 290.77h175.39q8.5 0 14.25-5.76t5.75-14.27q0-8.51-5.75-14.24t-14.25-5.73H467.69q-8.5 0-14.25 5.76-5.75 5.75-5.75 14.27 0 8.51 5.75 14.24t14.25 5.73Zm0-150.77h175.39q8.5 0 14.25-5.76t5.75-14.27q0-8.51-5.75-14.24T643.08-500H467.69q-8.5 0-14.25 5.76t-5.75 14.27q0 8.51 5.75 14.24t14.25 5.73Zm0-150.77h175.39q8.5 0 14.25-5.76 5.75-5.75 5.75-14.27 0-8.51-5.75-14.24t-14.25-5.73H467.69q-8.5 0-14.25 5.76t-5.75 14.27q0 8.51 5.75 14.24t14.25 5.73ZM224.62-160q-27.62 0-46.12-18.5Q160-197 160-224.62v-510.76q0-27.62 18.5-46.12Q197-800 224.62-800h510.76q27.62 0 46.12 18.5Q800-763 800-735.38v510.76q0 27.62-18.5 46.12Q763-160 735.38-160H224.62Z"/></svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>