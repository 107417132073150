<template>
    <div class="referral_bonus">
        <div class="flex" @click="show_main = !show_main">
            <div>
                <pizza :size="'33px'" :style="{'height': '40px'}"/>
            </div>
            <div>
                <div class="title">Referral Bonus</div>
                <div class="level">{{store.bonus_claimed.length}} Claimed</div>
            </div>
            <div class="arrow" :class="[show_main ? 'rot' : '']">
                <arrow_vue :size="'20px'"/>
            </div>
        </div>
        <div class="main" v-if="show_main">
            <bonus_vue v-for="(bonus, i) in store.invite_bonus" :invite="bonus" :key="i"/>

        </div>
    </div>
</template>

<script setup>
import pizza from '@/icons/pizza.vue'
import arrow_vue from '@/icons/arrow.vue';

import bonus_vue from './bonus.vue'


import { ref } from 'vue';
import { store } from '@/store/store';

const show_main = ref(false)

</script>

<style scoped>

.referral_bonus {
    margin-top: 20px;
    
    border: 1px solid #4b4f54;
    border-radius: 10px;
    overflow: hidden;

    fill: white;
}
.flex {

    display: grid;
    grid-template-columns: 40px auto max-content;
    align-items: center;
    gap: 15px;
    padding: 15px 10px;
}
.title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 3px;
}
.level {
    font-size: 12px;
    font-weight: 500;
    color: #dadada;
}
.arrow {
    margin-left: auto;
    transition: .2s;
}
.rot {
    transform: rotate(90deg);
}
.main {
    border-top: 1px solid #4b4f54;
    padding: 15px;
}
</style>