<template>
    <div class="task">
        <div class="task_flex" @click="open_channel()">
            <div class="icon">
                <telegram_vue :size="'30px'" :style="{'height': '30px'}"/>
            </div>
            <div>
                <div class="title">Join our telegram channel</div>
                <div class="flex">
                    <pizza_vue :size="'15px'"/>
                    +5,000
                </div>
            </div>
            <div class="arrow" :class="[show_main ? 'rot' : '']">
                <check_vue :size="'20px'" v-if="store.channel_claimed"/>
                <arrow_vue :size="'20px'" v-else/>
            </div>
        </div>
        <div class="task_main" v-if="show_main">
            
            <loading_vue :height="'52px'" v-if="loading"/>
            <div class="btn" v-else @click="claim()">Claim</div>
        </div>
    </div>
</template>

<script setup>
import arrow_vue from '@/icons/arrow.vue';
import check_vue from '@/icons/check.vue';
import pizza_vue from '@/icons/pizza.vue';
import telegram_vue from '../../icons/telegram.vue';

import loading_vue from '../loading.vue';

import { ref, inject } from 'vue';

const show_main = ref(false)

import { store } from '@/store/store';
const loading = ref(false)

const open_channel = () => {

    if (store.channel_claimed) return
    
    window.open('https://t.me/Bakeapizza', '_newtab');

    show_main.value = true
}

const axios = inject('axios')

import { toast } from "vue3-toastify";

const claim = () => {
    if (store.channel_claimed) return

    loading.value = true
    store.pause_emiting = true

    axios.post('/user/claim_channel_task', {
        'user_id': store.user_id
    }).then(data => {
        let r = data.data
        if (r.status == 'ok') {
            store.channel_claimed = true
            store.pizza += 5000
            store.total_pizza += 5000

            show_main.value = false

            toast.success('Task reward claimed')
            store.pause_emiting = false
        }
    })
}
</script>

<style scoped>
.task {
    margin-top: 20px;
    
    border: 1px solid #4b4f54;
    border-radius: 10px;
    overflow: hidden;

    fill: white;
}
.task_flex {

    display: grid;
    grid-template-columns: 40px auto max-content;
    align-items: center;
    gap: 15px;
    padding: 15px 10px;
}
.icon {
    display: flex;
    align-items: center;
    justify-content: center;
}
.title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 3px;
}
.flex {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    font-weight: 500;
    color: #dadada;
    fill: #FFD700;
}

.arrow {
    margin-left: auto;
    transition: .2s;
}
.rot {
    transform: rotate(90deg);
}


.task_main {
    border-top: 1px solid #4b4f54;
    padding: 10px;

    display: flex;
    flex-direction: column;
    gap: 10px;
    fill: black;
}
.btn {
    
    background-color: #FFD700;
    color: black;
    font-size: 18px;
    font-weight: 700;
    padding: 15px;
    text-align: center;
    border-radius: 10px;
}
</style>