<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M566.15-461.54h163.08v-40H566.15v40Zm0-110.77h163.08v-40H566.15v40ZM230.77-347.69h264.61v-6.62q0-35-35.15-54.19-35.15-19.19-97.15-19.19-62 0-97.16 19.19-35.15 19.19-35.15 54.19v6.62Zm132.31-144.62q25.3 0 42.65-17.34 17.35-17.35 17.35-42.66 0-25.31-17.35-42.65-17.35-17.35-42.65-17.35-25.31 0-42.66 17.35-17.34 17.34-17.34 42.65t17.34 42.66q17.35 17.34 42.66 17.34ZM184.62-200q-27.62 0-46.12-18.5Q120-237 120-264.62v-430.76q0-27.62 18.5-46.12Q157-760 184.62-760h590.76q27.62 0 46.12 18.5Q840-723 840-695.38v430.76q0 27.62-18.5 46.12Q803-200 775.38-200H184.62Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>