<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M467.69-793.08 223-651.46l257 148.54 257-148.54-244.69-141.62q-6.16-3.84-12.31-3.84t-12.31 
            3.84ZM160-335.62v-288.76q0-17.39 8.48-32.15 8.48-14.76 23.83-23.78l255.38-147.15q8.46-5 16-6.85 7.54-1.84 16.27-1.84t16.81 1.84q8.08 1.85 15.54 6.85l255.38 147.15q15.35 9.02 23.83 23.78 8.48 14.76 8.48 32.15v118.23q0 8.5-5.76 14.25t-14.27 5.75q-8.51 0-14.24-5.75T760-506.15v-112.93L511.31-475.46q-15.41 8.69-32.44 8.69-17.02 0-32.18-8.69L200-619.08v283.23q0 6.16 3.08 11.54 3.07 5.39 9.23 9.23L454.62-174q4.5 2.5 7.25 7.01t2.75 10.01q0 11.52-9.62 16.86-9.62 5.35-19.62-.26L192.31-279.69q-15.35-9.02-23.83-23.78-8.48-14.76-8.48-32.15ZM720-50q-66.85 0-113.42-46.58Q560-143.15 560-210t46.58-113.42Q653.15-370 720-370t113.42 46.58Q880-276.85 880-210T833.42-96.58Q786.85-50 720-50ZM480-481.77Zm224.62 316.54-53.39-53.39q-4.46-4.46-10.54-4.84-6.07-.39-11.31 4.84-5.23 5.24-5.23 10.93 0 5.69 5.23 10.92l68 68q5.24 5.23 10.8 7.35 5.56 2.11 11.92 2.11t11.82-2.11q5.46-2.12 10.7-7.35l68-68q4.46-4.46 4.84-10.54.39-6.07-4.84-11.31-5.24-5.23-10.93-5.23-5.69 0-11.19 5.5l-53.12 53.12v-133.23q0-6.16-4.61-10.77-4.62-4.62-10.77-4.62-6.15 0-10.77 4.62-4.61 4.61-4.61 10.77v133.23Z"/></svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>