<template>
    <div class="profile">
        <BackButton @click="router.go(-1)" />
        <div class="upper">
            <!-- <div class="page_title">Profile</div> -->

            <div class="profile_card">
                <div class="user">
                    <user_icon :size="'20px'" :height="'15px'"/>
                    {{ user }}
                </div>
                
                <div class="balance">
                    <pizza_icon :size="'35px'"/>
                    <div class="number">{{store.pizza.toLocaleString()}}</div>
                </div>
                
                <div class="box">
                    <div class="box_flex">
                        <div class="link">https://t.me/bakeapizza_bot?start={{store.telegram_id}}</div>
                        <div class="copy" @click="copy()">
                            <copy_vue :size="'30px'"/>
                        </div>
                    </div>
                    <div class="bonus">
                        Referral Bonus
                        <div class="bonus_pizza">
                        + <pizza_icon :size="'12px'"/> 500
                        </div>
                    </div>
                </div>
            </div>
            <referrals/>
            <referral_bonus/>
            <wallet/>

            


            <!-- <div class="section">
                <div class="title">
                    Referral List ({{ store.referrals }})
                </div>
                <div class="none">
                    <ref_icon :size="'25px'"/>
                    Your referrals will appear here
                </div>
            </div> -->

        </div>
        <div class="lower">
            <navigation_vue/>
        </div>
    </div>
</template>

<script setup>
import { BackButton } from 'vue-tg'

import user_icon from '@/icons/user.vue';

import pizza_icon from "@/icons/pizza.vue";

import copy_vue from '@/icons/copy.vue'
// import ref_icon from '@/icons/ref.vue';

import referrals from '@/components/profile/referrals.vue';
import referral_bonus from '@/components/profile/referral_bonus.vue';
import wallet from '@/components/profile/wallet.vue'

import navigation_vue from '@/components/navigation.vue'

import { store } from '@/store/store';

import { useRouter } from 'vue-router';
const router = useRouter()

import { useWebApp } from 'vue-tg'

const initdata = useWebApp()
const user = initdata.initDataUnsafe.user ? initdata.initDataUnsafe.user.first_name : 'None User'

import { toast } from "vue3-toastify";
const copy = () => {
    let text = `https://t.me/bakeapizza_bot?start=${store.telegram_id}`
    
    navigator.clipboard.writeText(text).then(function() {
        toast.success('Copied')
    });
}
</script>

<style scoped>

.profile {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.upper {
    flex: auto;
    padding: 20px;

    overflow: auto;
}

.profile_card {
    background-color: #553900;
    background: linear-gradient(to bottom right, rgba(255, 217, 0, 0.5), rgba(33, 176, 107, 0.5));
    border-radius: 10px;
    padding: 20px;
}
.user {
    font-size: 12px;
    font-weight: 500;

    display: flex;
    align-items: center;
    fill: white;
    gap: 5px;
}

.page_title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
}
.balance {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    fill: #FFD700;
    gap: 10px;
    margin: 40px 0px;
}
.number {
    font-size: 40px;
    font-weight: 600;
    line-height: 38px;
}

.box {
    border-radius: 10px;
    border: 1px solid #fff;

}
.box_flex {
    padding: 15px 20px;
    display: grid;
    grid-template-columns: auto max-content;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}
.link {
    font-size: 14px;
    line-height: 20px;

    user-select: text;
}
.copy {
    fill: white;
}
.bonus {
    border-top: 1px solid #4b4f54;
    border-top: 1px solid #fff;
    font-size: 12px;
    font-weight: 500;
    /* color: #dadada; */
    text-align: center;
    padding: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap:  10px;
    fill: #FFD700;
}
.bonus_pizza {
    display: flex;
    align-items: center;
    gap: 5px;
}

.section {
    margin-top: 20px;
}
.title {
    font-size: 14px;
    font-weight: 500;
    /* color: #dadada; */
}

.none {
    padding-top: 50px;
    text-align: center;
    fill: gray;
    color: gray;
    font-size: 12px;
}
</style>