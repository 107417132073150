<template>
    <div>

        
        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M891.54-600q0 63.62-23.35 120.35-23.34 56.73-62.27 101.57-6.61 7.08-15.38 8.46-8.77 
            1.39-15.85-5.69-6.84-6.84-6.23-15.61.62-8.77 7-16.08 34.62-39.54 54.19-88.85 19.58-49.3 19.58-104.15 
            0-54.85-19.58-103.92-19.57-49.08-54.19-88.62-6.61-7.31-7.11-16.08-.5-8.76 6.34-15.84 6.85-7.08 15.73-5.81 8.89 1.27 15.5 8.35 38.93 44.84 62.27 101.57 23.35 56.73 23.35 120.35Zm-188.16 0q0 25.08-8.46 48.42-8.46 23.35-23.38 42.2-5.62 7.3-14.12 7.8t-15.57-6.57Q635-515 634.88-523.5q-.11-8.5 5.04-16.27 9.85-13.31 15.27-28.61 5.43-15.31 5.43-31.62 0-17.85-5.43-32.38-5.42-14.54-15.27-28.08-5.15-7.77-5.04-16.16.12-8.38 6.97-15.23 7.07-7.07 15.57-6.57t14.12 7.8q14.92 18.85 23.38 41.43 8.46 22.57 8.46 49.19ZM348.46-480q-49.84 0-84.92-35.08-35.08-35.07-35.08-84.92t35.08-84.92Q298.62-720 348.46-720q49.85 0 84.92 35.08 35.08 35.07 35.08 84.92t-35.08 84.92Q398.31-480 348.46-480Zm-280 247.69v-24.31q0-27.61 13.92-47.76 13.93-20.16 39.31-32.08 48.69-23.69 108.08-39 59.38-15.31 118.69-15.31 59.31 0 118.69 15.31 59.39 15.31 108.08 39 25.39 11.92 39.31 32.08 13.92 20.15 13.92 47.76v24.31q0 17.62-11.96 29.58-11.96 11.96-29.58 11.96H110q-17.62 0-29.58-11.96-11.96-11.96-11.96-29.58Z"/></svg>


    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>