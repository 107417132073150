<template>
    <div class="ingredient">
        <div class="grid">
            <div>
                <div class="name">{{props.ingredient.name}}</div>
                <div class="numbers"><number_format :no="props.ingredient.balance"/>/<number_format :no="props.ingredient.max"/> {{props.ingredient.mesurement}}</div>
                <div class="per">
                    {{props.ingredient.amt_per_pizza}} {{ props.ingredient.mesurement }} per 
                    <pizza_icon :size="'10px'" :style="{'height': '15px'}"/>
                </div>
            </div>
            <div>
                <RadialProgress 
                :diameter="40"
                :completed-steps="props.ingredient.balance" :startColor="'#FFD700'"
                :total-steps="props.ingredient.max" :strokeWidth="3" :innerStrokeWidth="3">
                    <div class="percent">{{ parseInt((props.ingredient.balance / props.ingredient.max) * 100)}}%</div>
                </RadialProgress>
            </div>
        </div>
        <div class="tier">Tier {{ props.ingredient.tier }}</div>
    </div>
</template>

<script setup>
import RadialProgress from "vue3-radial-progress";

import number_format from '@/components/number_format.vue'
import pizza_icon from "@/icons/pizza.vue";

import { defineProps } from "vue";

const props = defineProps(['ingredient'])
</script>

<style scoped>
.ingredient {
    border: 1px solid #4b4f54;
    /* background-color: #282b30; */
    border-radius: 10px;
}
.grid {
    padding: 10px;
    display: grid;
    gap: 5px;
    grid-template-columns: auto 40px;
}
.name {
    font-size: 14px;
    font-weight: 600;
}
.numbers {
    margin-top: 5px;
    font-size: 10px;
    color: #dadada;
}
.per {
    margin-top: 5px;
    font-size: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
    fill: #FFD700;
    color: #dadada;
}
.percent {
    font-size: 10px;
    font-weight: 500;
}


.tier {
    padding: 5px;
    font-size: 12px;
    font-weight: 600;
    color: #dadada;
    text-align: center;
    border-top: 1px solid #4b4f54;
}
</style>