<template>
    <div class="popup">
        <div class="overlay" @click="close()"></div>
        <transition name="popup">
            <div class="box" v-if="show_box">
                <div class="upper">
                    <div class="name">{{ props.ingredient.name }}</div>
                    <div class="info">
                        <div class="label">Max Amount</div>
                        <div class="value">{{ (props.ingredient.max).toLocaleString() }} {{ props.ingredient.mesurement }}</div>
                    </div>
                    <div class="info">
                        <div class="label">Current Balance</div>
                        <div class="value">{{ (props.ingredient.balance).toLocaleString() }} {{ props.ingredient.mesurement }}</div>
                    </div>
                    <div class="info">
                        <div class="label">Recharge Speed</div>
                        <div class="value">{{ (props.ingredient.recharge_speed) }} {{ props.ingredient.mesurement }} / Sec</div>
                    </div>
                    <div class="info">
                        <div class="label">Amount Per Pizza</div>
                        <div class="value">
                            {{ (props.ingredient.amt_per_pizza) }} {{ props.ingredient.mesurement }} /
                            <pizza_icon :size="'15px'" :style="{'height': '15px'}"/>
                        </div>
                    </div>
                    <div class="info">
                        <div class="label">Tier</div>
                        <div class="value">Tier {{ (props.ingredient.tier) }} </div>
                    </div>
                </div>
                <div>
                    <div class="line"></div>
                    <div class="lower">
                        <div class="lower_text">
                            Upgrade for {{ (props.ingredient.tiers[props.ingredient.tier + 1]).toLocaleString() }}
                            <pizza_icon :size="'15px'" :style="{'height': '15px'}"/>
                        </div>
                        <div v-if="props.ingredient.can_lock && props.ingredient.lock" class="btn locked">
                            Locked
                        </div>
                        <div v-else>
                            <div v-if="loading">
                                <loading_vue :height="'51px'"/>
                            </div>
                            <div class="btn" v-else @click="upgrade()">Upgrade</div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, inject } from "vue";


import pizza_icon from "@/icons/pizza.vue";
import loading_vue from "../loading.vue";

import { useRouter } from "vue-router";
import { store } from '@/store/store'

const props = defineProps(['ingredient'])
const emit = defineEmits(['close'])

const show_box = ref(false)

const loading = ref(false)

const axios = inject('axios')
import { toast } from "vue3-toastify";

const close = () => {
    if (loading.value) {
        toast.info('Please wait')
    }
    show_box.value = false
    setTimeout(() => {
        emit('close')
    }, 200);
}



const upgrade = () => {
    if (props.ingredient.tier == 10) {
        toast.info("This ingredient is on the highest tier")
        return
    }
    let amount = props.ingredient.tiers[props.ingredient.tier + 1]

    if (store.pizza < amount) {
        toast.error("You don't have enough pizaa")
        return
    }

    store.pause_emiting = true
    loading.value = true

    axios.post('/user/upgrade_ingredient', {
        'name': props.ingredient.name,
        'user_id': store.user_id
    }).then(data => {
        let r = data.data
        if (r.status == 'ok') {
        
            for (let i = 0; i < store.ingredients.length; i++) {
                if (props.ingredient.name == store.ingredients[i].name) {
                    store.ingredients[i].max += store.ingredients[i].tier_upgrade[0]
                    store.ingredients[i].recharge_speed += store.ingredients[i].tier_upgrade[1]
                    store.ingredients[i].tier += 1

                    store.pizza -= amount

                    toast.success("Upgraded")

                    loading.value = false

                    close()

                    store.pause_emiting = false
                }
            }
        }

    })

}

const init = () => {
    setTimeout(() => {
        show_box.value = true
    }, 100);
}
init()


const router = useRouter()
router.beforeEach((to, from, next) => {
    if (loading.value) {
        next(false)
    } else {
        if (show_box.value) {
            close()
            next(false)
        } else {
            next()
        }
    }
})

</script>

<style scoped>
.popup {
    position: fixed;
    z-index: 10;
    top: 0px;
    left: 0px;

    width: 100%;
    height: 100%;

    display: flex;
    align-items: flex-end;
    
}
.overlay {
    position: fixed;
    top: 0px;
    left: 0px;

    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1px);
}
.box {
    position: relative;
    height: 70vh;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #111316;
    box-sizing: border-box;
    
    display: flex;
    flex-direction: column;
}
.upper {
    flex: auto;
    padding: 20px;
    overflow: auto;
}
.name {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
}
.info {
    margin-bottom: 20px;
}
.label {
    color: #dadada;
    font-size: 12px;
}
.value {
    font-size: 14px;
    font-weight: 500;
    margin-top: 2px;
    fill: #FFD700;

    display: flex;
    align-items: center;
    gap: 5px;
}


.line {
    height: 3px;
    background: linear-gradient(to right, #FFD700, #B5CB00);
}
.lower {
    padding: 20px;
}
.lower_text {
    font-size: 12px;
    margin-bottom: 7px;
    fill: #FFD700;
    display: flex;
    align-items: center;
    gap: 5px;
}
.btn {
    background-color: #FFD700;
    color: black;
    font-size: 18px;
    font-weight: 700;
    padding: 15px;
    text-align: center;
    border-radius: 10px;
}
.locked {
    background-color: #21252b;
    color: white;
}



.popup-enter-active {
    animation: pop .2s linear;
}
.popup-leave-active {
    animation: pop .2s linear reverse;
}

@keyframes pop {
    from {
        bottom: -60vh;
    }
    to {
        bottom: 0px;
    }
}

@media screen and (max-width: 700px) {
    .bellie {
        max-width: unset;
        width: 100%;
        padding: 20px;
    }
}
</style>