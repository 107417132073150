<template>
    <div class="tasks">
        <BackButton @click="router.go(-1)" />
        <div class="upper">
            <div class="page_title">Tasks</div>
            
            <div class="balance">
                <pizza_icon :size="'35px'"/>
                <div class="number">{{store.pizza.toLocaleString()}}</div>
            </div>

            <div class="section">
                <div class="title">Daily Tasks</div>

                <login_vue/>
                <!-- <referral_vue/> -->
            </div>
            <div class="section">
                <div class="title">General Tasks</div>

                <follow_vue/>
                <retweet_vue/>
                <channel_vue/>
            </div>
        </div>
        <div class="lower">
            <navigation_vue/>
        </div>
    </div>
</template>

<script setup>
import { BackButton } from 'vue-tg'

import pizza_icon from '@/icons/pizza.vue'

import login_vue from '@/components/tasks/login.vue'
// import referral_vue from '@/components/tasks/referral.vue'

import follow_vue from '@/components/tasks/follow.vue'
import retweet_vue from '@/components/tasks/retweet.vue'
import channel_vue from '@/components/tasks/channel.vue'

import navigation_vue from '@/components/navigation.vue'

import { store } from '@/store/store'

import { useRouter } from 'vue-router';
const router = useRouter()


import moment from 'moment'
var yesterday = moment("2024-06-02")
// var tomorrow = moment("2024-06-03")

let now = moment()

var diff = now.diff(yesterday, 'days')
console.log('diff', diff);

</script>

<style scoped>

.tasks {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.upper {
    flex: auto;
    padding: 20px;

    overflow: auto;
}

.page_title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
}

.balance {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    fill: #FFD700;
    gap: 10px;
    margin: 30px 0px;
}
.number {
    font-size: 40px;
    font-weight: 600;
    line-height: 38px;
}

.section {
    margin-bottom: 20px;
}
.title {
    color: #dadada;
    font-size: 14px;
}
</style>