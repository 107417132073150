<template>
    <div class="app">
        <router-view v-if="show_app"/>
    </div>
</template>

<script setup>


import { watch, onUnmounted, ref } from "vue";
import { store } from "./store/store";


import {state, socket} from './socket'


import { useWebAppTheme } from 'vue-tg'
const theme = useWebAppTheme()
theme.setHeaderColor('#21252b')

import { useWebAppViewport } from 'vue-tg'
import { useRoute, useRouter } from "vue-router";
const appViewport = useWebAppViewport()
appViewport.expand()


const route = useRoute()
const router = useRouter()


const show_app = ref(false)
const init = () => {
    if (!state.connected) {
        socket.connect()
    }

    setTimeout(() => {
        
        
        if (!route.query.userid) {
            let user_id = localStorage.getItem('user_id')

            router.push({
                path: '/',
                query: {
                    'userid': user_id ? user_id : ''
                }
            })

        }
        show_app.value = true
        
    }, 1000);
}
init()


let increase_guage = null

onUnmounted(() => {
    clearInterval(increase_guage)
})

watch(() => store.loaded, () => {
    if (store.loaded) {
        if (increase_guage != null) {
            clearInterval(increase_guage)
        }
        increase_guage = setInterval(() => {
            let is_update = false
            for (let i = 0; i < store.ingredients.length; i++) {
                if (store.ingredients[i].balance < store.ingredients[i].max) {
                    store.ingredients[i].balance += store.ingredients[i].recharge_speed

                    is_update = true

                    store.new_update = true
                } else {
                    store.ingredients[i].balance = store.ingredients[i].max
                }
            }
            if (is_update) store.updates++
        }, 1000);
    }
})


function emit_ingredient_update() {

    if (store.emitting) {
        return
    }

    
    store.emitting = true
    store.new_update = false

    let ingredients = []

    for (const x of store.ingredients) {
        ingredients.push({
            'name': x.name,
            'balance': x.balance
        })
    }

    socket.emit('ingredients_update', {
        'user_id': store.user_id,
        'total_pizza': store.total_pizza,
        'pizza': store.pizza,
        'ingredients': ingredients
    })
}


socket.on('ingredients_updated', () => {
    store.emitting = false
    
    if (store.pause_emiting) {
        return
    }

    if (store.new_update) emit_ingredient_update()
})


watch(() => store.updates, () => {

    if (store.pause_emiting) {
        return
    }
    emit_ingredient_update()
}, { deep: true })



</script>

<style>
@import 'https://fonts.googleapis.com/css2?family=Ubuntu+Sans:ital,wght@0,100..800;1,100..800&display=swap';

#app {
  font-family: "Ubuntu Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: flex;
  justify-content: center;
  background-color: #2e343c;
}

:root {
    
  --toastify-color-success: #FFD700 !important;
  --toastify-color-info: #FFD700 !important;
}
body {
    padding: 0px;
    margin: 0px;

    user-select: none;
}
a {
    color: inherit;
    fill: inherit;
    text-decoration: none;
}
input {
    font-family: "Ubuntu Sans", sans-serif;
    font-size: 16px;
}
.app {
    background-color: #21252b;
    color: white;

    height: var(--tg-viewport-height);
    max-width: 450px;
    width: 100%;
}


@media screen and (max-width:800px) {
    .app {
        max-width: 100%;
    }
}

</style>
