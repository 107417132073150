<template>
    <span class="number">
        {{ formatted }}
    </span>
</template>

<script setup>
import { defineProps, ref, watch } from 'vue'

const props = defineProps(['no'])

const formatted = ref('')

const format = () => {

    formatted.value = props.no

    let no_length = props.no.toString().length
    if (no_length > 3 && no_length < 7) {
        let fixed = 2
        if (no_length == 6) fixed = 1
        formatted.value = (props.no / 1000).toFixed(fixed) + 'K'
    }

    if (no_length > 6 && no_length < 9) {
        let fixed = 3
        formatted.value = (props.no / 1000000).toFixed(fixed) + 'M'
    }

}
format()

watch(() => props.no, () => {
    format()
})

</script>

<style scoped>

</style>