<template>
    <div class="ingredients">
        
        <BackButton @click="router.go(-1)" />
        <div class="upper">
            <div class="page_title">Ingredients</div>

            <div class="balance">
                <pizza_icon :size="'35px'"/>
                <div class="number">{{store.pizza.toLocaleString()}}</div>
            </div>

            <div class="options">
                <div class="option" @click="soon()">
                    <send_vue :size="'20px'"/>
                    <div class="option_text">
                        Send
                    </div>
                </div>
                <div class="option" @click="soon()">
                    <receive_vue :size="'20px'"/>
                    <div class="option_text">
                        Receive
                    </div>
                </div>
                <div class="option" @click="soon()">
                    <shop_vue :size="'20px'"/>
                    <div class="option_text">
                        Sell
                    </div>
                </div>
            </div>
            
            <div class="left_right">
                <div v-for="ingredient in store.ingredients" :key="ingredient.name" >
                    <ingredient_vue :ingredient="ingredient"/>
                </div>
            </div>

        </div>
        <div class="lower">
            <navigation_vue/>
        </div>
    </div>
</template>

<script setup>
import { BackButton } from 'vue-tg'

import pizza_icon from "@/icons/pizza.vue";

import send_vue from '@/icons/unfilled/send.vue'
import receive_vue from '@/icons/unfilled/receive.vue'
import shop_vue from '@/icons/unfilled/shop.vue'

import ingredient_vue from '@/components/ingredients/ingredient.vue'

import navigation_vue from '@/components/navigation.vue'

import { store } from '@/store/store'

const init = () => {

    for (let j = 0; j < store.ingredients.length; j++) {
        store.ingredients[j].show = true

        if (store.ingredients[j].can_lock) {
            if (store.total_pizza > store.ingredients[j].unlock_criteria.min) {
                store.ingredients[j].lock = false
            }
        }
    }
}
init()


import { toast } from "vue3-toastify";
import { useRouter } from 'vue-router';
const soon = () => {
    toast.info('This feature will be avaliable on main launch')
}

const router = useRouter()

</script>

<style scoped>

.ingredients {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.upper {
    flex: auto;
    padding: 20px;

    overflow: auto;
}

.page_title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
}



.balance {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    fill: #FFD700;
    gap: 10px;
    margin: 30px 0px;
}
.number {
    font-size: 40px;
    font-weight: 600;
    line-height: 38px;
}
.symbol {
    font-size: 10px;
    color: gray;
}


.options {
    display: grid;
    grid-template-columns: 33.333% 33.333% 33.333%;
    fill: white;
    background-color: #282b30;
    border-radius: 10px;
}
.option {
    text-align: center;
    padding: 10px 0px;

    display: flex;
    flex-direction: column;
    gap: 3px;
}
.option_text {
    font-size: 12px;
}

.left_right {
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    gap: 20px;
    margin-top: 20px;
}
</style>