<template>
    <div class="nav">
        <router-link to="/home">
            <div class="nav_item" :class="[route.name == 'home' ? 'active_nav' : '']">
                <div class="icon">
                    <home_vue :size="'20px'" v-if="route.name == 'home'"/>
                    <home_icon :size="'20px'" v-else/>
                </div>
                <div class="nav_text">Home</div>
            </div>
        </router-link>
        <router-link to="/ingredients">
            <div class="nav_item" :class="[route.name == 'ingredients' ? 'active_nav' : '']">
                <div class="icon">
                    <mine_vue :size="'20px'" v-if="route.name == 'ingredients'"/>
                    <mine_icon :size="'20px'" v-else/>
                </div>
                <div class="nav_text">Ingre...</div>
            </div>
        </router-link>
        <!-- <div class="nav_item">
            <div class="icon">
                <boost_icon :size="'20px'"/>
            </div>
            <div class="nav_text">Boost</div>
        </div> -->
        <router-link to="/tasks">
            <div class="nav_item" :class="[route.name == 'tasks' ? 'active_nav' : '']">
                <div class="icon">
                    <task_vue :size="'20px'" v-if="route.name == 'tasks'"/>
                    <task_icon :size="'20px'" v-else/>
                </div>
                <div class="nav_text">Tasks</div>
            </div>
        </router-link>
        <router-link to="/profile">
            <div class="nav_item" :class="[route.name == 'profile' ? 'active_nav' : '']">
                <div class="icon">
                    <user_vue :size="'20px'" v-if="route.name == 'profile'"/>
                    <user_icon :size="'20px'" v-else/>
                </div>
                <div class="nav_text">Profile</div>
            </div>
        </router-link>
        <div class="nav_item" @click="soon()">
            <div class="icon">
                <shop :size="'20px'"/>
            </div>
            <div class="nav_text">Market</div>
        </div>
    </div>
</template>

<script setup>
import home_vue from "@/icons/home.vue";
import mine_vue from "@/icons/mine.vue";
// import boost_vue from "@/icons/boost.vue";
import task_vue from "@/icons/task.vue";
import user_vue from "@/icons/user.vue";

import home_icon from "@/icons/unfilled/home.vue";
import mine_icon from "@/icons/unfilled/mine.vue";
// import boost_icon from "@/icons/unfilled/boost.vue";
import task_icon from "@/icons/unfilled/task.vue";
import user_icon from "@/icons/unfilled/user.vue";
import shop from "@/icons/unfilled/shop.vue";

import { useRoute } from "vue-router";

const route = useRoute()

import { toast } from "vue3-toastify";
const soon = () => {
    toast.info('Market will be avaliable on main launch')
}

</script>

<style scoped>
.nav {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    border-radius: 6px;
    overflow: hidden;
    margin: 20px;
    /* padding: 10px; */
    background-color: #282b30;
}
.nav_item {
    fill: gray;
    text-align: center;
    padding: 15px 0px;
}
.nav_text {
    font-size: 12px;
    color: gray;
}
.active_nav {
    fill: white;
}
.active_nav .nav_text {
    color: white;
    font-size: 14px;
    font-weight: 500;
}
</style>