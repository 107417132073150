<template>
    <div class="splash">
        <div class="content">
            <div class="pizza">
                <img src="../assets/pizza.png"/>
            </div>
            <div class="name">Bake A Pizza</div>
            <div class="text">
                Welcome to the first Pizza Factory built for all Layer 2 explorers.
                Gather ingredients and bake pizza with a simple tap.
            </div>
            <div v-if="loading">
                <loading_vue :height="'53px'"/>
            </div>
            <div class="start" @click="start()" v-else>Start Baking</div>
        </div>
    </div>
</template>

<script setup>
// import Localbase from 'localbase';
// const db = new Localbase('pizza')

import loading_vue from '@/components/loading.vue'

import { useWebApp } from 'vue-tg'
import { toast } from 'vue3-toastify';

const initdata = useWebApp()
console.log(initdata);

import { inject, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { store } from '@/store/store';
import {state, socket} from '../socket'

const route = useRoute()
const router = useRouter()
const axios = inject('axios')

const loading = ref(false)
const loaded = ref(false)
const init = () => {
    
    let platform = initdata.platform

    if (platform == 'ios' || platform == 'android') {
        loading.value = true

        if (route.query.userid) {
            
            store.user_id = route.query.userid

            localStorage.setItem('user_id', route.query.userid)

            axios.post('/user/onboard', {
                'user_id': route.query.userid
            }).then(data => {
                let r = data.data
                if (r.status == 'ok') {
                    store.referrals = r.referrals
                    store.telegram_id = r.telegram_id

                    store.invite_bonus = r.invite_bonus
                    store.bonus_claimed = r.bonus_claimed

                    store.daily_login_count = r.daily_login_count
                    store.daily_login_last = r.daily_login_last
                    store.today = r.today

                    store.retweet_link = r.retweet_link
                    
                    store.follow_claimed = r.follow_claimed
                    store.retweet_claimed = r.retweet_claimed
                    store.channel_claimed = r.channel_claimed

                    store.total_pizza = r.total_pizza
                    store.pizza = r.pizza

                    store.ingredients = r.ingredients
                    store.levels = r.levels

                    let current_time = r.current_time
                    
                    for (let i = 0; i < store.ingredients.length; i++) {

                        let last_time = store.ingredients[i]['updated_at']

                        const seconds_pass = parseInt((current_time - last_time) / 1000)
                        
                        store.ingredients[i].balance += seconds_pass * store.ingredients[i].recharge_speed
                        
                        if (store.ingredients[i].balance > store.ingredients[i].max) {
                            store.ingredients[i].balance = store.ingredients[i].max
                        }
                    }

                    store.updates++

                    store.loaded = true

                    if (state.connected) {
                        setTimeout(() => {
                            router.push('/home')
                        }, 1500);
                    }
                    loaded.value = true

                }
            })

            socket.emit('authenticate', {
                'user_id': route.query.userid
            })
        }
    }
    
}
init()

const start = () => {
    let platform = initdata.platform
    if (platform == 'tdesktop') {
        toast.error('Bake a pizza avaliable only on telegram mobile')
    } else {
        window.open('https://t.me/bot_name', '_newtab');
    }
}


socket.on('authenticated', () => {
    if (route.name == 'splash' && loaded.value) {
        
        store.loaded = true

        setTimeout(() => {
            router.push('/home')
        }, 1500);
    }
})

</script>

<style scoped>
.splash {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
    padding: 20px;
    box-sizing: border-box;
}
.content {
    width: 100%;
}
.pizza {
    fill: #FFD700;
    text-align: center;
}
.pizza img {
    width: 50%;
}
.name {
    text-align: center;
    font-size: 25px;
    font-weight: 800;
    margin-top: 20px;
}
.text {
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
    margin: 20px 0px;
    line-height: 22px;
}
.start {
    background-color: #FFD700;
    color: black;
    font-weight: 600;
    padding: 17px;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
}
</style>