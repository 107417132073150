import { createRouter, createWebHistory } from 'vue-router'

import splash from '../views/splash.vue'
import HomeView from '../views/HomeView.vue'
import ingredients from '../views/ingredients.vue'
import tasks from '../views/tasks.vue'
import profile from '@/views/profile.vue'

const routes = [
    {
        path: '/',
        name: 'splash',
        component: splash
    },
    {
        path: '/home',
        name: 'home',
        component: HomeView
    },
    {
        path: '/ingredients',
        name: 'ingredients',
        component: ingredients
    },
    {
        path: '/tasks',
        name: 'tasks',
        component: tasks
    },
    {
        path: '/profile',
        name: 'profile',
        component: profile
    }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
