<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M224.62-120q-26.85 0-45.74-18.88Q160-157.77 160-184.62v-430.76q0-26.85 18.88-45.74Q197.77-680 
            224.62-680H320q0-66.85 46.58-113.42Q413.15-840 480-840t113.42 46.58Q640-746.85 640-680h95.38q26.85 0 45.74 18.88Q800-642.23 
            800-615.38v430.76q0 26.85-18.88 45.74Q762.23-120 735.38-120H224.62Zm0-40h510.76q9.24 0 16.93-7.69 7.69-7.69 
            7.69-16.93v-430.76q0-9.24-7.69-16.93-7.69-7.69-16.93-7.69H224.62q-9.24 0-16.93 7.69-7.69 7.69-7.69 16.93v430.76q0 
            9.24 7.69 16.93 7.69 7.69 16.93 7.69ZM480-440q66.85 0 113.42-46.58Q640-533.15 640-600h-40q0 50-35 85t-85 35q-50 
            0-85-35t-35-85h-40q0 66.85 46.58 113.42Q413.15-440 480-440ZM360-680h240q0-50-35-85t-85-35q-50 0-85 35t-35 
            85ZM200-160v-480 480Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>