<template>
    <div class="level">
        <div class="level_info">
            <div class="level_name">
                {{props.level_details.title}} >
            </div>
            <div class="level_max">
                
                <number_format :no="store.total_pizza"/> /
                <number_format :no="props.level_details.max"/>
            </div>
        </div>
        <div class="bar">
            <div class="progress" :style="{'width': progress_percent}"></div>
        </div>
    </div>
</template>

<script setup>
import number_format from './number_format.vue';

import { store } from '@/store/store'
import { computed, defineProps } from "vue";

const props = defineProps(['level_details'])

const progress_percent = computed(() => {
    return ((store.total_pizza / props.level_details.max) * 100) +'%'
})

</script>

<style scoped>
.level {
    
}
.level_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.level_name {
    font-weight: 500;
    font-size: 12px;
    color: gray;
}
.level_max {
    font-weight: 600;
    font-size: 14px;
}

.bar {
    margin-top: 10px;
    background-color: #fff;
    /* padding: 3px; */
    border-radius: 10px;
    height: 10px;
    box-sizing: border-box;
    overflow: hidden;
}
.progress {
    background: linear-gradient(to right, #FFD700, #21B06C);
    height: 100%;
    border-radius: 5px;
}
</style>