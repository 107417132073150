<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960" >
            <path d="M200-280v80-560 480Zm24.62 120q-26.85 0-45.74-18.88Q160-197.77 160-224.62v-510.76q0-26.85 18.88-45.74Q197.77-800 
            224.62-800h510.76q26.85 0 45.74 18.88Q800-762.23 
            800-735.38v117.69h-40v-117.69q0-10.77-6.92-17.7-6.93-6.92-17.7-6.92H224.62q-10.77 0-17.7 6.92-6.92 6.93-6.92 17.7v510.76q0 
            10.77 6.92 17.7 6.93 6.92 17.7 6.92h510.76q10.77 0 17.7-6.92 6.92-6.93 6.92-17.7v-117.69h40v117.69q0 26.85-18.88 
            45.74Q762.23-160 735.38-160H224.62Zm320-160q-26.85 0-45.74-18.88Q480-357.77 480-384.62v-190.76q0-26.85 18.88-45.74Q517.77-640 
            544.62-640h230.76q26.85 0 45.74 18.88Q840-602.23 840-575.38v190.76q0 26.85-18.88 
            45.74Q802.23-320 775.38-320H544.62Zm230.76-40q10.77 0 17.7-6.92 6.92-6.93 
            6.92-17.7v-190.76q0-10.77-6.92-17.7-6.93-6.92-17.7-6.92H544.62q-10.77 0-17.7 6.92-6.92 6.93-6.92 17.7v190.76q0 
            10.77 6.92 17.7 6.93 6.92 17.7 6.92h230.76ZM640-420q25 0 42.5-17.5T700-480q0-25-17.5-42.5T640-540q-25 0-42.5 
            17.5T580-480q0 25 17.5 42.5T640-420Z"/>
        </svg>


    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>