<template>
    <div class="loading" :style="{'height': props.height}">
        <div class="spin">
            <pizza_vue :size="'25px'"/>
        </div>
    </div>
</template>

<script setup>
import pizza_vue from "@/icons/pizza.vue";
import { defineProps } from "vue";

const props = defineProps(['height'])

</script>

<style scoped>
.loading {
    
    background-color: #FFD700;
    color: black;
    font-weight: 600;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
}
.spin {
    animation: 2s spin infinite linear;

    width: 25px;
    height: 25px;
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>