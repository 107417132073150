<template>
    <div class="plus">
        +1 <pizza :size="'20px'"/>
    </div>
</template>

<script setup>
import pizza from "@/icons/pizza.vue";

import { ref, defineProps, defineEmits } from "vue";

const props = defineProps(['data'])
const emit = defineEmits(['close'])

const x = ref(props.data.x + 'px')
const y = ref(props.data.y + 'px')

setTimeout(() => {
    emit('close')
}, 1000);

</script>


<style scoped>
.plus {
    position: fixed;
    z-index: 20;

    color: white;
    font-weight: 800;
    font-size: 18px;

    animation: move_up 1s linear;

    display: flex;
    gap: 10px;
    fill: #FFD700;
}
@keyframes move_up {
    from {
        top: v-bind(y);
        left: v-bind(x);
        opacity: 1;
    }
    to {
        top: 95px;
        left: 50%;
        opacity: .5;
    }
}
</style>