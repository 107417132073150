<template>
    <div class="wallet">
        <div class="flex" @click="show_main = !show_main">
            <div>
                <wallet_vue :size="'40px'" :style="{'height': '40px'}"/>
            </div>
            <div>
                <div class="title">Wallet</div>
                <div class="level">Link Wallet</div>
            </div>
            <div class="arrow" :class="[show_main ? 'rot' : '']">
                <arrow_vue :size="'20px'"/>
            </div>
        </div>
        <div class="main" v-if="show_main">
            <div class="btn" @click="soon()">Connect Wallet</div>
        </div>
    </div>
</template>

<script setup>
import wallet_vue from '@/icons/wallet.vue'
import arrow_vue from '@/icons/arrow.vue';
import { ref } from 'vue';

const show_main = ref(false)

import { toast } from "vue3-toastify";
const soon = () => {
    toast.info('Wallet connect will be avaliable soon')
}
</script>

<style scoped>

.wallet {
    margin-top: 20px;
    
    border: 1px solid #4b4f54;
    border-radius: 10px;
    overflow: hidden;

    fill: white;
}
.flex {

    display: grid;
    grid-template-columns: 40px auto max-content;
    align-items: center;
    gap: 15px;
    padding: 15px 10px;
}
.title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 3px;
}
.level {
    font-size: 12px;
    font-weight: 500;
    color: #dadada;
}
.arrow {
    margin-left: auto;
    transition: .2s;
}
.rot {
    transform: rotate(90deg);
}

.main {
    border-top: 1px solid #4b4f54;
    padding: 15px;
}

.btn {
    padding: 13px;
    text-align: center;
    border: 1px solid #4b4f54;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
}
</style>