import { reactive } from 'vue'

export const store = reactive({

    daily_login_count: 0,
    daily_login_last: '',
    today: '',

    retweet_link: '',

    follow_claimed: false,
    retweet_claimed: false,
    channel_claimed: false,

    user_id: '',
    telegram_id: '',
    referrals: 0,

    invite_bonus: [],
    bonus_claimed: [],

    total_pizza: 100,
    pizza: 100,

    loaded: false,

    updates: 0,
    new_update: false,
    emitting: false,
    pause_emiting: false,

    guage_max: 1000,
    guage_balance: 1000,
    recharge_speed: 1,
    tap_count: 1,

    
    ingredients: [
        {
            'show': false,
            'lock': false,
            'can_lock': false,
            'name': 'Dough',
            'max': 1000,
            'balance': 1000,
            'recharge_speed': 1,
            'mesurement': 'PCS',
            'amt_per_pizza': 1,
            'tier': 0,
            'tiers': [
                0, 1000, 2000, 3000, 5000, 8000, 10000, 15000, 20000, 25000, 300000
            ],
            'tier_upgrade': [500, 1]
        },
        {
            'show': false,
            'lock': true,
            'can_lock': true,
            'unlock_criteria': {
                'min': 0
            },
            'name': 'Beef',
            'max': 1000,
            'balance': 1000,
            'recharge_speed': 1,
            'mesurement': 'Lb',
            'amt_per_pizza': 1,
            'tier': 0,
            'tiers': [
                0, 1500, 3000, 4500, 7000, 10000, 15000, 20000, 25000, 30000, 50000
            ],
            'tier_upgrade': [500, 1]
        },
        {
            'show': false,
            'lock': true,
            'can_lock': true,
            'unlock_criteria': {
                'min': 30000
            },
            'name': 'Chicken',
            'max': 500000,
            'balance': 500000,
            'recharge_speed': 500,
            'mesurement': 'G',
            'amt_per_pizza': 500,
            'tier': 0,
            'tiers': [
                0, 1500, 3000, 4500, 7000, 10000, 15000, 20000, 25000, 30000, 50000
            ],
            'tier_upgrade': [250000, 500]
        },
        {
            'show': false,
            'lock': true,
            'can_lock': true,
            'unlock_criteria': {
                'min': 1000000
            },
            'name': 'Vegetable',
            'max': 500000,
            'balance': 500000,
            'recharge_speed': 500,
            'mesurement': 'G',
            'amt_per_pizza': 500,
            'tier': 0,
            'tiers': [
                0, 1500, 3000, 4500, 7000, 10000, 15000, 20000, 25000, 30000, 50000
            ],
            'tier_upgrade': [250000, 500]
        },
        {
            'show': false,
            'lock': false,
            'can_lock': false,
            'name': 'Cheese',
            'max': 100000,
            'balance': 100000,
            'recharge_speed': 80,
            'mesurement': 'G',
            'amt_per_pizza': 80,
            'tier': 0,
            'tiers': [
                0, 500, 1000, 2000, 3000, 5000, 8000, 10000, 12000, 15000, 20000
            ],
            'tier_upgrade': [50000, 80]
        },
        {
            'show': false,
            'lock': false,
            'can_lock': false,
            'name': 'Toppings',
            'max': 100000,
            'balance': 100000,
            'recharge_speed': 50,
            'mesurement': 'G',
            'amt_per_pizza': 50,
            'tier': 0,
            'tiers': [
                0, 500, 1000, 2000, 3000, 5000, 8000, 10000, 12000, 15000, 20000
            ],
            'tier_upgrade': [50000, 50]
        }
    ],

    levels: [
        {
            title: 'Beef Miner',
            min: 0,
            max: 30000,
            ingredients: [
                'Dough', 'Beef', 'Cheese', 'Toppings'
            ],
            ingredients_index: []
        },
        {
            title: 'Chicken Miner',
            min: 30000,
            max: 1000000,
            ingredients: [
                'Dough', 'Chicken', 'Cheese', 'Toppings'
            ],
            ingredients_index: []
        },
        {
            title: 'Vegetable Miner',
            min: 1000000,
            max: 100000000,
            ingredients: [
                'Dough', 'Vegetable', 'Cheese', 'Toppings'
            ],
            ingredients_index: []
        }
    ]
})