<template>

        <svg enable-background="new -0.881 -1.086 141.732 141.732"  :width="size" :height="size" version="1.1" 
        viewBox="-0.881 -1.086 141.732 141.732" xml:space="preserve" 
        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g >
                <g>
                    <path d="M123.057,74.451c0,4.848-3.929,8.772-8.771,8.772c-4.846,0-8.775-3.928-8.775-8.772c0-4.848,3.931-8.773,8.775-8.773    
                    C119.128,65.678,123.057,69.605,123.057,74.451 M82.109,93.469c0,3.229-2.619,5.85-5.85,5.85c-3.231,0-5.851-2.619-5.851-5.85    
                    c0-3.231,2.618-5.849,5.851-5.849C79.489,87.62,82.109,90.238,82.109,93.469 M70.88,112.342c3.015,0,5.458,2.443,5.458,5.455    
                    c0,3.014-2.442,5.457-5.458,5.457s-5.455-2.443-5.455-5.457C65.425,114.785,67.867,112.342,70.88,112.342 M64.56,22.836    
                    c0,4.846-3.929,8.771-8.771,8.771c-4.846,0-8.774-3.928-8.774-8.771c0-4.848,3.93-8.775,8.774-8.775    
                    C60.631,14.061,64.56,17.99,64.56,22.836 M46.732,68.896c1.5,0,2.717,1.197,2.717,2.674c0,1.479-1.217,2.679-2.717,2.679    
                    c-1.502,0-2.719-1.197-2.719-2.679C44.013,70.094,45.23,68.896,46.732,68.896 M44.013,98.793c0,4.504-3.65,8.151-8.152,8.151    
                    c-4.504,0-8.152-3.647-8.152-8.151s3.648-8.154,8.152-8.154C40.363,90.639,44.013,94.289,44.013,98.793 M36.654,51.508    
                    c0,3.014-2.441,5.457-5.455,5.457s-5.455-2.443-5.455-5.457c0-3.012,2.441-5.455,5.455-5.455    
                    C34.21,46.053,36.654,48.496,36.654,51.508 M134.539,44.975L72.227,69.9l25.44-63.603c-8.59-3.795-18.09-5.904-28.083-5.904    
                    C31.154,0.393,0,31.547,0,69.977s31.152,69.584,69.586,69.584c38.433,0,69.584-31.154,69.584-69.584    
                    C139.17,61.164,137.527,52.734,134.539,44.975"/>
                    
                    <path d="M116.705,22.918c0,3.164-2.563,5.729-5.729,5.729c-3.162,0-5.729-2.562-5.729-5.729s2.564-5.729,5.729-5.729    
                    C114.141,17.189,116.705,19.754,116.705,22.918 M104.198,42.971c0,2.084-1.688,3.773-3.771,3.773    
                    c-2.085,0-3.773-1.689-3.773-3.773s1.688-3.773,3.773-3.773C102.51,39.198,104.198,40.887,104.198,42.971 M139.971,37.811    
                    C133.523,20.924,120.588,7.287,104.244,0L79.582,62.168L139.971,37.811z"/>
                </g>
            </g>
            <g/>
        </svg>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>
    .icon {
        /* width: fit-content; */
    }
</style>