<template>
    <div class="bonus">
        <!-- <div>
            <user_vue :size="'30px'"/>
        </div> -->
        <div>
            <div class="name">
                 {{ props.invite.refferal }} Invite
            </div>
            <div class="pizza">
                <pizza_vue :size="'13px'"/> {{ props.invite.reward }}
            </div>
        </div>
        <div class="flex_left">
            <div v-if="can_claim">
                <div class="claiming" v-if="loading">Claiming...</div>
                <div v-else>
                    <div class="cliamed" v-if="claimed">Bonus Claimed</div>
                    <div class="claim" v-else @click="claim()">Claim Bonus</div>
                </div>
            </div>
            <div v-else>
                · · ·
            </div>
        </div>
    </div>
</template>

<script setup>
import pizza_vue from '@/icons/pizza.vue';
import { store } from '@/store/store';
import { defineProps, ref, inject } from 'vue';

const props = defineProps(['invite'])

const can_claim = ref(false)
const claimed = ref(false)

const init = () => {
    if (store.referrals >= props.invite.refferal) {
        can_claim.value = true

        if (store.bonus_claimed.includes(props.invite.refferal)) {
            claimed.value = true
        }
    }
}
init()


import { toast } from "vue3-toastify";
const loading = ref(false)
const axios = inject('axios')

const claim = () => {

    loading.value = true

    store.pause_emiting = true

    axios.post('/user/invite_bonus_claim',{
        'user_id': store.user_id,
        'refferal': props.invite.refferal
    }).then(data => {
        let r = data.data
        if (r.status == 'ok') {
            toast.success('Bonus Claimed')

            store.total_pizza += r.bonus
            store.pizza += r.bonus
            store.bonus_claimed.push(props.invite.refferal)

            claimed.value = true

            loading.value = false
            
            store.pause_emiting = false
        } 
    })
    
}

</script>

<style scoped>

.bonus {
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 5px;

    padding: 10px 5px;
    border-bottom: 1px solid #4b4f54;
    margin-bottom: 10px;
}
.name {
    font-weight: 600;
}
.pizza {
    margin-top: 5px;
    font-size: 12px;

    display: flex;
    align-items: center;
    gap: 5px;
    fill: #FFD700;
}
.flex_left {
    margin-left: auto;
    font-size: 12px;
    font-weight: 500;
}
.claim {
    padding: 5px;
    color: #FFD700;
}
</style>